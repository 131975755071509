.appContainerStyles {
    min-height: 100vh;
    margin-top: 108px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    box-sizing: border-box;
}

.appContainerStyles.native {
    margin-top: 0;
}

.divider {
    height: 1px;
    background: #002b67;
    margin-top: 30px;
    margin-bottom: 30px;
}

.loadingScreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #fff;
    z-index: 2000;
}

.loadingText {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 300;
    letter-spacing: 1.5px;
    color: #002b67;
    font-size: 15px;
}

@media (min-width: 1360px) {
    .appContainerStyles {
        margin-top: 152px;
    }

    .appContainerStyles.native {
        margin-top: 0;
    }
}

.ticketCheckerButton {
    color: #002b67;
    background: none;
    border: none;
    cursor: pointer;
    letter-spacing: 0.56px;
    padding: 8px 0;
}
