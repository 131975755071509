body > iframe {
    display: none;
}

.no-scroll-y {
    overflow-y: hidden;
    position: fixed;
    width: 100%;
    height: 100vh;
}
.loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
}
.loader {
    border: 3px solid #eee;
    border-top: 3px solid transparent;
    border-radius: 25px;
    width: 45px;
    height: 45px;
    animation: spin 2s linear infinite;
}

.mk-sports button#mk_pageChat {
    z-index: 201;
}

.bto-sb-mb-iphone .bto-sb-mb-footer, .bto-sb-mb-footer {
    z-index: 10 !important;
}


@media(max-width: 768px) {
    .mk-sports button#ui-to-top.active {
        right: 10px;
        bottom: 100px;
    }
    
    .mk-sports button#mk_pageChat {
        right: 5px;
        bottom: 170px;
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


/* MOVE THESE TO CMS (and remove the importants) */
.SideNav a {
    color: #002B67 !important;
}

.carousel-banner .rd-banner {
    height: 100% !important;
    border-radius: 12px !important;
}

body {
    background: #EFEFF0 !important;
}